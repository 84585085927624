export const items = [
  {
    title: '대시보드',
    icon: 'mdi-tablet-dashboard',
    to: '/dashboard'
  },
  // {
  //   title: '가맹정별 현황',
  //   icon: 'mdi-office-building-marker-outline',
  //   to: '/agencyStat'
  // },
  // {
  //   title: '접촉 이력 조회',
  //   icon: 'mdi-order-bool-ascending-variant',
  //   to: '/accidentHistory'
  // },
  {
    title: '출동 결과 관리',
    icon: 'mdi-tow-truck',
    to: '/dispatch'
  },
  {
    title: '상담사 관리',
    icon: 'mdi-headset',
    to: '/counselor'
  },
  {
    title: '워킹그룹 관리',
    icon: 'mdi-account-group',
    to: '/workgroup'
  },
  {
    title: '설정',
    icon: 'mdi-cog-outline',
    group: 'settings',
    children: [
      // {
      //   title: '내 설정',
      //   icon: 'mdi-account',
      //   to: 'my'
      // },
      {
        title: '메시지 템플릿',
        icon: 'mdi-email-outline',
        to: 'messageTemplate'
      },
      // {
      //   title: '대시보드 설정',
      //   icon: 'mdi-monitor-dashboard',
      //   to: 'dashboard'
      // },
      {
        title: '센터 정보',
        icon: 'mdi-camera-metering-center',
        to: 'centerInfo'
      },
      {
        title: '비밀번호 변경',
        icon: 'mdi-form-textbox-password',
        to: 'resetPassword'
      },
    ]
  }
]
